import React from 'react'
import { FtpBanner, InviteBanner } from '../../components/banners'
import { Swiper, AutoPlaySwipeableViews, Button, Wrapper, Link } from '@raysync/common/components'
import Layout from '../../components/layout'
import {
  Title,
  Desc,
  ChallengeSection,
  FtpChallengeList,
  FtpChallengeItem,
  SolutionSection,
  FtpFeatureSection,
  ValueSection,
  ExampleSection,
  ExampleList,
  FtpWrap,
  CardContainer,
  CardButtonGroup,
  CardButton,
  CardContent,
  CardWrapper,
  ScenarioList,
  ScenarioItem,
  CardButtonBg,
} from './atoms'

import Feature1 from './images/ftp/feature1.png'
import Feature2 from './images/ftp/feature2.png'
import Feature3 from './images/ftp/feature3.png'
import Feature4 from './images/ftp/feature4.png'
import Feature5 from './images/ftp/feature5.png'
import Feature6 from './images/ftp/feature6.png'
import Feature7 from './images/ftp/feature7.png'

import Scenario1 from './images/ftp/scenario1.png'
import Scenario2 from './images/ftp/scenario2.png'
import Scenario3 from './images/ftp/scenario3.png'
import Scenario4 from './images/ftp/scenario4.png'

import ChallengeIcon1 from '@raysync/common/components/svg/products/upgrade/icon1'
import ChallengeIcon2 from '@raysync/common/components/svg/products/upgrade/icon2'
import ChallengeIcon3 from '@raysync/common/components/svg/products/upgrade/icon3'
import ChallengeIcon4 from '@raysync/common/components/svg/products/upgrade/icon4'
import ChallengeIcon5 from '@raysync/common/components/svg/products/upgrade/icon5'
import ChallengeIcon6 from '@raysync/common/components/svg/products/upgrade/icon6'
import Arch from '@raysync/common/components/svg/products/upgrade/arch'
import Example1 from './images/ftp/example.png'
import { useMedia } from '@raysync/common/hooks'

const FeatureCard = () => {
  const isMobile = useMedia()
  const [currentIndex, setCurrentIndex] = React.useState(0)
  const featureList = [
    {
      title: '高性能传输',
      desc:
        '镭速传输为实现稳定的高速文件传输，通过内置超高速传输协议，智能压缩等技术，传输速度提升到FTP 的100倍。同时对网络带宽自动智能优化，充分利用现有带宽，有效降低网络延时、丢包等影响，实现超远程、跨境大文件、海量小文件高速传输。无需特别调整即可发挥高效、稳定性能。',
      img: Feature1,
    },
    {
      title: '数据稳定高效',
      desc:
        '在传输过程中，为确保数据传输稳定、完整性，镭速传输通过断点续传、错误重传、多重文件校验、智能同步等机制，确保数据在传输过程中因网络故障、传输异常等情况发生时，数据传输能够获得有效保障，同时缩短输出耗时，减轻工作负担。',
      img: Feature2,
    },
    {
      title: '企业级安全性',
      desc:
        '提供完善的安全机制，确保文件传输中的每一个环节都是安全的。支持对传输的文件进行类型、大小限制，敏感词检测，防病毒扫描。登录访问黑白名单，多因子认证，防止暴力破解。多文件传输验证（Hash、Rsync），确保数据一致性。',
      img: Feature3,
    },
    {
      title: '集成控制',
      desc: '镭速通过集中的管理调度，使企业能够集中管理全球传输节点，实现快速、安全、可靠的传输流程。',
      img: Feature4,
    },
    {
      title: '可视化的文件传输流转',
      desc: '实时监控所有传输节点的状态、进度，图形化查看日志和审计传输历史记录。',
      img: Feature5,
    },
    {
      title: '多方式传输',
      desc:
        '镭速传输支持根据不同的传输发起方提供多种模式传输方式。支持点对点、多点互传，数据云传输，一对多数据分发等模式。提供专属用户、用户组空间，支持特定权限管理，构建跨部门、跨公司的专属协助空间及文件传输需求。',
      img: Feature6,
    },
    {
      title: '出色的兼容性',
      desc:
        '支持 UNIX、Linux、Windows 等操作系统，支持 SMB/NFS 网络存储，云对象存储。提供SDK和API，快速与现有系统集成，轻松构建企业内部、企业之间、异构系统之间的自动化文件传输网络。',
      img: Feature7,
    },
  ]
  const obj = React.useMemo(() => {
    let element = null
    if (typeof window !== 'undefined') {
      element = document.getElementById(`card-button-${currentIndex}`)
    }
    return {
      left: element ? element.offsetLeft : 40,
      width: element ? element.offsetWidth : 100,
    }
  }, [currentIndex])
  if (isMobile) {
    return (
      <CardWrapper>
        {featureList.map(item => (
          <CardContent>
            <img src={item.img} alt={item.title} />
            <div>
              <h4>{item.title}</h4>
              <p>{item.desc}</p>
            </div>
          </CardContent>
        ))}
      </CardWrapper>
    )
  }
  return (
    <>
      <CardButtonGroup>
        {featureList.map((item, index) => (
          <CardButton
            id={`card-button-${index}`}
            actived={currentIndex === index}
            onClick={() => {
              setCurrentIndex(index)
            }}
          >
            {item.title}
          </CardButton>
        ))}
        <CardButtonBg {...obj} />
      </CardButtonGroup>
      <CardContainer>
        <AutoPlaySwipeableViews
          index={currentIndex}
          interval={5000}
          enableMouseEvents
          onChangeIndex={index => {
            setCurrentIndex(index)
          }}
        >
          {featureList.map(item => (
            <CardContent>
              <img src={item.img} alt={item.title} />
              <div>
                <h4>{item.title}</h4>
                <p>{item.desc}</p>
              </div>
            </CardContent>
          ))}
        </AutoPlaySwipeableViews>
      </CardContainer>
    </>
  )
}

class FtpUpgrade extends React.PureComponent {
  state = {
    challengeList: [
      {
        title: '传输速度慢',
        desc: '基于TCP的传输原理，面对海量文件传输效率较低，带宽利用率低',
        svg: <ChallengeIcon1 />,
      },
      {
        title: '不稳定',
        desc: '难以应对如断点续传、错误重传、加密传输、并行处理等场景',
        svg: <ChallengeIcon2 />,
      },
      {
        title: '不安全',
        desc: '安全管理机制较弱，不符合等保测评的安全传输要求',
        svg: <ChallengeIcon3 />,
      },
      {
        title: '拓展性差',
        desc: '由于架构受限，无法满足大规模集群式传输模式',
        svg: <ChallengeIcon4 />,
      },
      {
        title: '管理难',
        desc: '管理方式较为单一，很难适应精细化管理的要求',
        svg: <ChallengeIcon5 />,
      },
      {
        title: '审计难',
        desc: '无法提供详尽的日志记录，流程记录，不满足合规要求',
        svg: <ChallengeIcon6 />,
      },
    ],
    scenarioList: [
      {
        title: '跨国数据传输',
        img: Scenario1,
        url: '/solutions/multi-transfer/',
      },
      {
        title: 'FTP/SFTP替代',
        img: Scenario2,
        url: '#',
      },
      {
        title: '数据同步',
        img: Scenario3,
        url: '/solutions/data-sync/',
      },
      {
        title: '数据分发',
        img: Scenario4,
        url: '/solutions/data-distribution/',
      },
    ],
  }
  render() {
    const { location } = this.props
    const { challengeList, scenarioList } = this.state
    return (
      <Layout pageType='ftp' location={location}>
        <FtpWrap>
          <FtpBanner />
          <ChallengeSection>
            <Wrapper>
              <Title>FTP替代升级面临的挑战</Title>
              <Desc>
                随着信息技术的快速发展和网络安全环境的日益严峻，传统的FTP文件传输协议由于其固有的局限性和安全缺陷，逐渐无法满足现代企业的文件传输需求。因此，寻找FTP的替代方案并进行升级成为许多企业面临的重要任务。
              </Desc>
              <FtpChallengeList>
                {challengeList.map((l, i) => (
                  <FtpChallengeItem key={i}>
                    {l.svg}
                    <h4>{l.title}</h4>
                    <p>{l.desc}</p>
                  </FtpChallengeItem>
                ))}
              </FtpChallengeList>
            </Wrapper>
          </ChallengeSection>
          <SolutionSection>
            <Wrapper>
              <Title>FTP替代升级解决方案</Title>
              <Desc>
                镭速传输基于自研Raysync高速传输协议，在任何网络条件下都能充分利用网络带宽资源，实现TB级别大文件和海量小文件的高速传输。通过优化传输机制，支持断点续传、文件校验、多类型文件并发传输，全程采用金融级传输加密算法，有效保证海量文件的高速、稳定、可靠传输
              </Desc>
              <Arch />
            </Wrapper>
          </SolutionSection>
          <FtpFeatureSection>
            <Wrapper>
              <Title>产品特点</Title>
              <Desc>
                企业往往拥有多个网络，如办公网、生产网、研发网等，这些网络之间需要进行文件传输，以满足不同部门之间的协同工作需求，传统的文件传输方式，如U盘或即时通讯工具虽然能满足企业基础的跨域文件交换需求，但在实际应用中，企业仍存在管理及建设痛点
              </Desc>
              <FeatureCard />
            </Wrapper>
          </FtpFeatureSection>
          <ValueSection>
            <Wrapper>
              <Title>应用场景</Title>
              <ScenarioList>
                {scenarioList.map((l, i) => (
                  <ScenarioItem key={i} bg={l.img}>
                    <h4>{l.title}</h4>
                    <Button as='a' href={l.url} target={l.url !== '#' ? '_blank' : undefined} variant='outlined'>
                      了解更多
                    </Button>
                  </ScenarioItem>
                ))}
              </ScenarioList>
            </Wrapper>
          </ValueSection>
          <ExampleSection>
            <Wrapper>
              <Title>客户案例</Title>
              <ExampleList>
                <img src={Example1} alt='bank image' />
                <div>
                  <h3>某世界500强科技公司</h3>
                  <h4>客户痛点：</h4>

                  <p>1.文件数量庞大且格式复杂，至少有上亿个文件量</p>
                  <p>2.文件遍历、文件解/压缩耗时较长，浪费大量的时间在等待任务处理</p>
                  <p>3.大量小文件同步耗时时间长，有时会导致rsync进程中断或挂起，稳定性差</p>
                  <p>4.传输服务与单台文件存储服务器的连接存在瓶颈，导致无法充分利用带宽</p>
                  <Link to={'/example/example-detail-1103.html'}>
                    <Button size='large'>查看具体解决方案</Button>
                  </Link>
                </div>
              </ExampleList>
            </Wrapper>
          </ExampleSection>

          <Swiper>
            <InviteBanner type='ftp' />
          </Swiper>
        </FtpWrap>
      </Layout>
    )
  }
}

export default FtpUpgrade
