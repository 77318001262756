import React from 'react'

const SvgIcon6 = props => (
  <svg fill='none' width={30} height={30} viewBox='0 0 30 30' {...props}>
    <defs>
      <clipPath id='icon6_svg__a'>
        <rect width={30} height={30} rx={0} />
      </clipPath>
    </defs>
    <g clipPath='url(#icon6_svg__a)'>
      <path
        d='M23.357 24c-.643 0-1.071-.429-1.071-1.071 0-.643.428-1.072 1.071-1.072.857 0 1.5-.643 1.5-1.5s-.643-1.5-1.5-1.5H19.93c-.858 0-1.5.643-1.5 1.5s.642 1.5 1.5 1.5c.642 0 1.07.429 1.07 1.072C21 23.57 20.572 24 19.93 24c-1.93 0-3.643-1.714-3.643-3.643 0-1.928 1.714-3.643 3.643-3.643h3.642c1.929 0 3.643 1.715 3.643 3.643 0 1.929-1.714 3.643-3.857 3.643z'
        fill='#252A3A'
      />
      <path
        d='M25.714 30h-8.143c-2.143 0-3.643-1.714-3.643-3.643v-.643c0-2.143 1.715-3.643 3.643-3.643h2.357c.643 0 1.072.429 1.072 1.072s-.429 1.071-1.072 1.071h-2.357c-.857 0-1.714.857-1.714 1.715v.428c0 .857.857 1.714 1.714 1.714h8.143c.857 0 1.714-.857 1.714-1.714v-.643c0-.857-.857-1.714-1.714-1.714l-1.5-.214c-.643 0-.857-.429-.857-1.072s.643-.857 1.071-.857l1.5.214c1.93 0 3.643 1.715 3.643 3.643v.429C29.357 28.286 27.643 30 25.714 30z'
        fill='#252A3A'
      />
      <path
        d='M11.357 30H5.786C2.57 30 0 27.429 0 24.429V5.57C0 2.571 2.571 0 5.786 0h18.643C27.429 0 30 2.571 30 5.571v7.286c0 .643-.429 1.072-1.071 1.072-.643 0-1.072-.429-1.072-1.072V5.571c0-1.928-1.5-3.428-3.428-3.428H5.786c-1.929 0-3.429 1.5-3.429 3.428v18.643c0 1.929 1.5 3.429 3.429 3.429h5.571c.643 0 1.072.428 1.072 1.071 0 .857-.429 1.286-1.072 1.286z'
        fill='#252A3A'
      />
      <path
        d='M19.071 10.5H6.214c-.643 0-1.071-.429-1.071-1.071 0-.643.428-1.072 1.071-1.072h12.857c.643 0 1.072.429 1.072 1.072 0 .642-.429 1.071-1.072 1.071zM15.429 15H6.857c-.643 0-1.071-.643-1.071-1.071 0-.429.428-1.072 1.071-1.072h8.572c.642 0 1.07.429 1.07 1.072 0 .642-.642 1.071-1.07 1.071z'
        fill='#252A3A'
      />
    </g>
  </svg>
)

export default SvgIcon6
