import React from 'react'

const SvgIcon3 = props => (
  <svg fill='none' width={30} height={30} viewBox='0 0 30 30' {...props}>
    <defs>
      <clipPath id='icon3_svg__a'>
        <rect width={30} height={30} rx={0} />
      </clipPath>
    </defs>
    <g clipPath='url(#icon3_svg__a)'>
      <path
        d='M24.194 14.995c0 6.018-7.427 10.548-9.527 11.7-2.1-1.172-9.528-5.7-9.528-11.7V6.692l9.527-3.496 9.528 3.496v8.303zm1.322-10.09L15.076 1.09a.97.97 0 00-.819 0L3.817 4.905C3.33 5.065 3 5.539 3 6.076v8.92c0 8.361 10.675 13.667 11.14 13.883.156.08.332.121.526.121.195 0 .35-.06.525-.12.467-.218 11.142-5.562 11.142-13.884v-8.92c0-.537-.33-1.012-.817-1.171z'
        fill='#252A3A'
      />
      <path
        d='M19.003 9.886c.44.44.44 1.168 0 1.627L16.3 14.221l2.703 2.699c.44.438.44 1.166 0 1.625a1.158 1.158 0 01-1.647 0l-2.687-2.69-2.699 2.704c-.44.44-1.167.44-1.625 0a1.134 1.134 0 010-1.628l2.698-2.704-2.713-2.717c-.44-.44-.44-1.166 0-1.625.44-.439 1.168-.439 1.627 0l2.715 2.71 2.707-2.71c.44-.439 1.166-.439 1.625 0z'
        fill='#252A3A'
      />
    </g>
  </svg>
)

export default SvgIcon3
