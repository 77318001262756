import React from 'react'

const SvgIcon1 = props => (
  <svg fill='none' width={30} height={30} viewBox='0 0 30 30' {...props}>
    <defs>
      <clipPath id='icon1_svg__a'>
        <rect width={30} height={30} rx={0} />
      </clipPath>
    </defs>
    <g clipPath='url(#icon1_svg__a)'>
      <path
        d='M15 1.25c7.594 0 13.75 6.156 13.75 13.75S22.594 28.75 15 28.75 1.25 22.594 1.25 15 7.406 1.25 15 1.25zm-.938 2.539C8.703 4.23 4.406 8.433 3.82 13.75H6.25c.345 0 .625.28.625.625v1.25c0 .345-.28.625-.625.625H3.819a11.253 11.253 0 0010.244 9.961V23.75c0-.345.28-.625.624-.625h1.25c.346 0 .626.28.626.625v2.392a11.257 11.257 0 009.618-9.892H23.75a.625.625 0 01-.625-.625v-1.25c0-.345.28-.625.625-.625h2.431c-.564-5.11-4.552-9.188-9.619-9.893V6.25c0 .345-.28.625-.625.625h-1.25a.625.625 0 01-.624-.625V3.789zm6.563 14.15c.345 0 .625.28.625.625v1.25c0 .345-.28.625-.625.625H9.375a.625.625 0 01-.625-.625v-1.25c0-.345.28-.625.625-.625h11.25zm0-8.125c.345 0 .625.28.625.625v1.25c0 .345-.28.625-.625.625H9.375a.625.625 0 01-.625-.625v-1.25c0-.345.28-.625.625-.625h11.25z'
        fill='#252A3A'
      />
    </g>
  </svg>
)

export default SvgIcon1
