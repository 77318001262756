import styled, { css } from 'styled-components'
import { Wrapper } from '@raysync/common/components'
import { Media } from '@raysync/common/theme'
import ChallengeBg from './images/challenge.png'
import FtpChallengeBg from './images/ftp/challenge.png'
import ValueBg from './images/value_bg.png'
export const Title = styled.h2`
  text-align: center;
  color: #3d3d3d;
  font-size: 36px;
  margin-bottom: 18px;
  ${Media.phone`
    font-size: .4rem;
    margin-bottom: .24rem;
  `}
`
export const Desc = styled.p`
  text-align: center;
  color: #636772;
  font-size: 14px;
  line-height: 24px;
  max-width: 860px;
  margin: 0 auto 42px;
  ${Media.phone`
    font-size: .24rem;
    line-height: 1.5;
    margin-bottom: .36rem;
    padding: 0 .1rem;
  `}
`
export const FerryWrap = styled.div``
export const FtpWrap = styled(FerryWrap)`
  ${Desc} {
    max-width: 960px;
  }
`
export const ChallengeSection = styled.div`
  padding: 60px 0 80px;
  ${Media.phone`
    padding: .8rem 0;
  `}
`
export const ChallengeList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-image: url(${ChallengeBg});
  background-repeat: no-repeat;
  background-position: top center;
  ${Media.phone`
    background: unset;
  `}
`

export const ChallengeItem = styled.div`
  width: 400px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 20px 30px;
  gap: 6px;
  background: #f9fafd;
  h4 {
    font-size: 20px;
    font-weight: bold;
    color: #3d3d3d;
    margin-top: 2px;
  }
  p {
    font-size: 14px;
    line-height: 1.6;
    color: #636772;
  }
  &:nth-child(2n) {
    align-items: flex-start;
    margin-left: 20px;
  }
  &:nth-child(2n - 1) {
    p {
      text-align: right;
    }
  }
  &:nth-child(3) {
    margin-top: 30px;
    margin-left: 70px;
  }
  &:nth-child(4) {
    margin-top: 30px;
    margin-right: 70px;
  }
  ${Media.phone`
    width: 100%;
    border-radius: .2rem;
    padding: .32rem .4rem;
    margin: 0 0 .24rem 0 !important;
    align-items: flex-start !important;
    h4 {
      font-size: .32rem;
    }
    p {
      font-size: .24rem;
      text-align: left  !important;
    }
  `}
`
export const FtpChallengeList = styled(ChallengeList)`
  background-image: url(${FtpChallengeBg});
  background-position: center center;
`
export const FtpChallengeItem = styled(ChallengeItem)`
  width: 380px;
  padding: 20px;
  margin: 0 40px 20px !important;
  &:nth-child(3) {
    margin-left: 0px !important;
  }
  &:nth-child(4) {
    margin-right: 0px !important;
  }
`

export const SolutionSection = styled.div`
  padding: 40px 0 70px;
  background: #f9fafd;
  svg {
    display: block;
    margin: 0 auto;
  }
  ${Media.phone`
    svg {
      width: 100%;
      height: unset;
    }
  `}
`

export const FeatureSection = styled.div`
  padding: 60px 0 54px;
  ${Media.phone`
    padding: .5rem 0;
  `}
`
export const FtpFeatureSection = styled.div`
  padding: 80px 0 70px;
  ${Media.phone`
    padding: .8rem 0;
  `}
`
export const FeatureList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`
export const FeatureItem = styled.div`
  width: 380px;
  height: 213px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 6px;
  margin-bottom: 30px;
  background: #f9fafd;
  h4 {
    font-size: 20px;
    font-weight: bold;
    color: #3d3d3d;
    margin-top: 2px;
  }
  p {
    font-size: 14px;
    line-height: 1.6;
    color: #636772;
  }
  ${Media.phone`
    width: 100%;
    height: unset;
    padding: .4rem;
    border-radius: .2rem;
    margin-bottom: .24rem;
    svg {
      width: .96rem;
      height: .96rem;
    }
    h4 {
      font-size: .32rem;
    }
    p {
      font-size: .24rem;
    }
  `}
`
export const ValueSection = styled.div`
  padding: 60px 0 40px;
  background-color: #f9fafd;
  background-image: url(${ValueBg});
  background-repeat: no-repeat;
  ${Media.phone`
    padding: .5rem 0;
    background-position: left bottom;
  `}
`
export const ValueList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 30px;
  ${Media.phone`
    padding-top: .4rem;
  `}
`
export const ValueItem = styled.div`
  width: 590px;
  height: 150px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  padding: 20px;
  margin-bottom: 20px;
  gap: 10px;
  background: #ffffff;
  svg {
    min-width: 60px;
    height: 60px;
  }
  h4 {
    font-size: 20px;
    font-weight: bold;
    color: #3d3d3d;
    margin-bottom: 6px;
  }
  p {
    font-size: 14px;
    line-height: 1.6;
    color: #636772;
  }
  ${Media.phone`
    width: 100%;
    height: unset;
    padding: .3rem .4rem;
    border-radius: .2rem;
    margin-bottom: .4rem;
    display: block;
    svg {
      width: .96rem;
      height: .96rem;
    }
    h4 {
      font-size: .32rem;
    }
    p {
      font-size: .24rem;
    }
  `}
`
export const ExampleSection = styled.div`
  padding: 60px 0 100px;
  ${Media.phone`
    padding: .8rem 0 1rem;
  `}
`
export const ExampleList = styled.div`
  margin-top: 48px;
  border: 1px solid #f7f5f5;
  border-radius: 10px;
  box-shadow: 0px 10px 30px 0px rgba(144, 151, 173, 0.3);
  height: 440px;
  display: flex;
  img {
    width: 50%;
    border-radius: 10px 0 0 10px;
  }
  > div {
    width: 50%;
    padding: 40px;
    h3 {
      font-size: 24px;
      margin-bottom: 24px;
      font-weight: bold;
      color: #3d3d3d;
    }
    h4 {
      font-size: 20px;
      font-weight: bold;
      color: #3d3d3d;
      margin-bottom: 6px;
    }
    p {
      font-size: 14px;
      line-height: 1.6;
      color: #636772;
      margin-bottom: 6px;
    }
    button {
      margin-top: 20px;
    }
  }
  ${Media.phone`
    width: 100%;
    height: unset;
    border-radius: .2rem;
    margin-top: .56rem;
    display: block;
    img {
      width: 100%;
      border-radius: .2rem .2rem 0 0;
    }
    div {
      width: 100%;
      padding: .4rem;
      h3 {
        font-size: .36rem;
        margin-bottom: .2rem;
      }
      h4 {
        font-size: .32rem;
      }
      p {
        font-size: .24rem;
        margin-bottom: .06rem;
      }
      button {
        margin-top: .3rem;
      }
    }
  `}
`
export const CardButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 50px;
  margin-bottom: 12px;
  position: relative;
`
export const CardButtonBg = styled.span`
  position: absolute;
  left: ${p => (p.left ? p.left + 'px' : '0')};
  width: ${p => (p.width ? p.width + 'px' : '100px')};
  top: 0;
  height: 48px;
  line-height: 48px;
  border-radius: 30px;
  background: #252a3a;
  color: #fff;
  transition-property: left, width;
  transition-duration: 0.3s, 0.1s;
  transition-delay: 0s, 0.1s;
  z-index: -1;
`
export const CardButton = styled.span`
  position: relative;
  height: 48px;
  line-height: 48px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  border-radius: 30px;
  padding: 0 20px;
  cursor: pointer;
  background: ${p => (p.actived ? '#252a3a' : 'transparent')};
  color: ${p => (p.actived ? '#fff' : '#636772')};
`
export const CardContainer = styled.div`
  position: relative;
  border-radius: 20px;
  box-shadow: 0px 10px 30px 0px rgba(144, 151, 173, 0.3);
`
export const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`
export const CardContent = styled.div`
  height: 320px;
  border-radius: 20px;
  display: flex;
  padding: 20px;
  gap: 20px;
  background: #ffffff;

  img {
    border-radius: 10px;
    width: 560px;
    height: 280px;
  }
  div {
    padding: 30px 60px 0 50px;
    h4 {
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 20px;
      color: #3d3d3d;
    }
    p {
      font-size: 16px;
      line-height: 1.5;
      color: #636772;
    }
  }
  ${Media.phone`
    display: block;
    height: unset;
    padding: .32rem .3rem;
    background: #FFFFFF;
    margin-bottom: .4rem;
    box-shadow: 0px 15px 45px 0px rgba(144, 151, 173, 0.3);
    img {
      width: 100%;
      height: 3rem
    }
    div {
      padding: .2rem 0 0;
      h4 {
        font-size: .36rem;
        margin-bottom: .1rem;
      }
      p {
        font-size: .24rem;
      }
    }
  `}
`
export const ScenarioList = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 48px 0 80px;
  ${Media.phone`
    flex-wrap: wrap;
    margin: .64rem 0 0;
  `}
`
export const ScenarioItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 280px;
  height: 200px;
  border-radius: 10px;
  background-image: url(${p => p.bg});
  background-size: 100% 100%;

  h4 {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    color: #3d3d3d;
    padding-top: 60px;
  }
  a {
    max-width: 120px;
    margin: 20px auto 0;
    border: unset;
  }
  :hover {
    box-shadow: 0px 10px 20px 0px rgba(144, 151, 173, 0.3);
  }
  ${Media.phone`
    height: 4rem;
    width: 6.4rem;
    margin: 0 auto .4rem;
    h4 {
      font-size: .4rem;
      padding-top: 1rem;
    }
    a {
      max-width: 2.3rem;
      margin-top: .3rem;
    }
  `}
`
