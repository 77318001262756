import React from 'react'

const SvgIcon2 = props => (
  <svg fill='none' width={30} height={30} viewBox='0 0 30 30' {...props}>
    <defs>
      <clipPath id='icon2_svg__a'>
        <rect width={30} height={30} rx={0} />
      </clipPath>
    </defs>
    <g clipPath='url(#icon2_svg__a)'>
      <path
        d='M29.121 10.482a20.867 20.867 0 00-14.124-5.481A20.856 20.856 0 00.873 10.482a.939.939 0 00-.058 1.325.939.939 0 001.324.058 18.995 18.995 0 0112.858-4.992c4.954 0 9.469 1.893 12.859 4.992a.936.936 0 101.266-1.383z'
        fill='#252A3A'
      />
      <path
        d='M18.72 10.438a15.91 15.91 0 00-3.72-.436c-4.04 0-7.734 1.506-10.541 3.984a.937.937 0 101.242 1.407 14.002 14.002 0 019.302-3.516c.413 0 .823.018 1.23.053a7.375 7.375 0 012.488-1.492zM7.96 17.561a10.892 10.892 0 016.205-2.529 7.371 7.371 0 00-.395 1.925 8.998 8.998 0 00-4.603 2.04.938.938 0 01-1.207-1.436zm6.803 3.703a1.877 1.877 0 00-1.638 1.86c0 1.034.84 1.875 1.875 1.875.888 0 1.632-.615 1.825-1.444a7.498 7.498 0 01-2.062-2.291zm6.486-7.826a.936.936 0 00-.937.938v3.75a.936.936 0 101.875 0v-3.75a.937.937 0 00-.938-.938zm0 6.25a.936.936 0 00-.937.937v.126a.936.936 0 101.875 0v-.126a.937.937 0 00-.938-.937z'
        fill='#252A3A'
      />
      <path
        d='M21.249 9.999a7.5 7.5 0 00-7.5 7.5 7.5 7.5 0 007.5 7.5 7.5 7.5 0 007.5-7.5 7.5 7.5 0 00-7.5-7.5zm0 13.125a5.626 5.626 0 01-5.625-5.625 5.626 5.626 0 015.625-5.625 5.625 5.625 0 110 11.25z'
        fill='#252A3A'
      />
    </g>
  </svg>
)

export default SvgIcon2
