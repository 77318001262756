import React from 'react'

const SvgIcon4 = props => (
  <svg fill='none' width={30} height={30} viewBox='0 0 30 30' {...props}>
    <defs>
      <clipPath id='icon4_svg__a'>
        <rect width={30} height={30} rx={0} />
      </clipPath>
    </defs>
    <g clipPath='url(#icon4_svg__a)'>
      <path
        d='M15 1.875c7.249 0 13.125 5.876 13.125 13.125S22.249 28.125 15 28.125 1.875 22.249 1.875 15 7.751 1.875 15 1.875zm8.59 5.86L7.733 23.588A11.25 11.25 0 0023.59 7.734zM15 3.75a11.25 11.25 0 00-8.59 18.516L22.267 6.41A11.205 11.205 0 0015 3.75z'
        fill='#252A3A'
      />
    </g>
  </svg>
)

export default SvgIcon4
